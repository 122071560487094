import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import auth from "../../api/auth-api";
import { LOGIN_USER, LOGOUT_USER } from "../actions";

import { loginUserSuccess, loginUserError } from "./actions";

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithUsernamePassword);
}

const loginWithUsernamePasswordAsync = async (username, password) =>
  await auth
    .signInWithUsernameAndPassword({ username, password })
    .then((authUser) => authUser)
    .catch((error) => error);

function* loginWithUsernamePassword({ payload }) {
  const { username, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithUsernamePasswordAsync, username, password);
    if (!loginUser.data.msg) {
      localStorage.setItem("user_token", loginUser.data.token);
      yield put(loginUserSuccess(loginUser.data.user));
      history.push("/");
    } else {
      yield put(loginUserError(loginUser.data.msg));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push("/");
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem("user_token");
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}
