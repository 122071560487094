import { GET_LANGS, GET_LANGS_SUCCESS, GET_LANGS_ERROR } from "../actions";

const INIT_STATE = {
  langs: [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LANGS:
      return Object.assign({}, state, {
        langs: [],
        loading: true,
      });
    case GET_LANGS_SUCCESS:
      return Object.assign({}, state, {
        langs: action.payload,
        loading: false,
      });
    case GET_LANGS_ERROR:
      return Object.assign({}, state, {
        langs: [],
        loading: false,
      });
    default:
      return { ...state };
  }
};
